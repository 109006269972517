import type { SubscriptionEventCommonParameters } from "@product/scmp-sdk/dist/types/lib/google-analytics/ga4-types";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { rosettaAtom } from "scmp-app/lib/rosetta";

export const useRosettaGA4TrackingParameters = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  return useMemo<SubscriptionEventCommonParameters>(() => {
    if (asyncRosettaState?.status !== "success") return {};

    const rosettaInstance = asyncRosettaState.result ?? {};
    if (!rosettaInstance || !rosettaInstance?.isScmpSubscriber) return {};

    const baseParameters: SubscriptionEventCommonParameters =
      rosettaInstance.instance?.rulesEngine.getOutcome("tracking.ga4.baseParameters") ?? {};

    return baseParameters;
  }, [asyncRosettaState?.result, asyncRosettaState?.status]);
};
