import type { EventEmitter } from "ahooks/lib/useEventEmitter";
import useEventEmitter from "ahooks/lib/useEventEmitter";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

type EventType = {
  eventName: "showInlineWall";
  payload: boolean;
};

type ContextValue = {
  eventEmitter?: EventEmitter<EventType>;
};

const RosettaContext = createContext<ContextValue>({
  eventEmitter: undefined,
});

export type Props = {
  children?: ReactNode;
};

// Not able to use jotai so we need to use context to pass the event emitter across components
export const RosettaContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const eventEmitter = useEventEmitter<EventType>();
  return (
    <RosettaContext.Provider
      value={{
        eventEmitter,
      }}
    >
      {children}
    </RosettaContext.Provider>
  );
};
RosettaContextProvider.displayName = "RosettaContextProvider";

export const useRosettaContext = () => useContext(RosettaContext);
